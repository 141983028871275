import { getGlobalParamsListItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'general.name'
    },
    {
      prop: 'tableId',
      label: 'Template',
      formatter: (row) => {
        return getGlobalParamsListItemLabel(row, 'paramTemplate', {
          isNeedPreFix: false
        })
      }
    },
    {
      prop: 'groupId',
      label: 'Template Group',
      formatter: (row) => {
        return getGlobalParamsListItemLabel(row, 'terminalParamGroup', {
          isNeedPreFix: false
        })
      }
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
