<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-template-group-setting')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isPush"
          class="opt-btn"
          icon="el-icon-upload2"
          size="mini"
          plain
          type="warning"
          @click="handleOperationClick(scope.row.id)"
          >{{ $t('general.push') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'
import i18n from '@/i18n'
import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'parameter', // 所属的模块
  pageName: 'templateSetting', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  pageAction: 'param/getTemplateGroupSettingList', // 获取表格的Action
  deleteRecordAction: 'param/deleteTemplateGroupSettingById', // 删除record的Action
  pageCountGetter: 'param/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'param/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const { t } = i18n.global
const pageContentRef = ref('')
const pageSearchRef = ref('')
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const isPush = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'push'
)

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/template/setting/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/template/setting/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/template/setting/new`)
}

const handleOperationClick = async (id) => {
  ElMessageBox.confirm(
    'Are you sure to push this template group?',
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      await handleMessageCommit(`param/handlePublishTemplateGroup`, id).then(
        () => {
          pageContentRef.value.getPageData()
        }
      )
    })
    .catch((action) => {
      if (action === 'cancel') {
        ElMessage({
          type: 'info',
          message: t('popup.cancel-tip')
        })
      }
    })
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
